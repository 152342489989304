.grading {
  h2 {
    position: relative;
    margin-bottom: 25px;
    text-transform: capitalize;
    font-size: 2rem;
    text-align: left;
    font-family: "Poppins";
    font-weight: 500;
  }
  p {
  }
  h5 {
    font-size: 20px;
  }
  .grading-details {
    border-bottom: solid 2px darkgray;
    padding-block: 30px 45px;

    &:last-child {
      border-bottom: none;
    }
  }
  table {
    tr {
      td,
      th {
        font-size: 20px;
        padding: 5px;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        white-space: pre;
        padding-inline: 10px;
        border: solid 1px #808080;
        min-width: 180px;
      }
    }
    .font-normal {
      tr,
      td {
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 576px) {
  .grading {
    .grading-details {
      padding-block: 30px;
    }
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 14px;
    }
    .visual-img {
      img {
        max-width: 65px;
      }
    }
    table {
      tr {
        td,
        th {
          font-size: 16px;
        }
      }
    }
  }
}
