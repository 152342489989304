@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./Assets/Scss/Theme.module.scss);

// Colors
$primary: #303030;
$offwhite: #f5f5f5;
$accent: #4caf50;
$border: #d8d8d8;
$gray: #eeeeee;
$white: #ffffff;
$black: #000000;

/* ------------- Font Poppins ------------- */

@font-face {
  font-family: "Poppins";
  src: url("Assets/Fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
    url("Assets/Fonts/Poppins/Poppins-Regular.woff") format("woff"),
    url("Assets/Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Assets/Fonts/Poppins/Poppins-Medium.woff2") format("woff2"),
    url("Assets/Fonts/Poppins/Poppins-Medium.woff") format("woff"),
    url("Assets/Fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Assets/Fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"),
    url("Assets/Fonts/Poppins/Poppins-SemiBold.woff") format("woff"),
    url("Assets/Fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Assets/Fonts/Poppins/Poppins-Bold.woff2") format("woff2"),
    url("Assets/Fonts/Poppins/Poppins-Bold.woff") format("woff"),
    url("Assets/Fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ------------- Font Poppins ------------- */

/* ------------- Font Montserrat ------------- */
@font-face {
  font-family: "Montserrat";
  src: url(Assets/Fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
}
/* ------------- Font Montserrat ------------- */

/* ------------- Font Optima Nova ------------- */

@font-face {
  font-family: "Optima";
  src: url("Assets/Fonts/Optimanova/Optimanova.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ------------- Font Optima Nova ------------- */

html {
  // scroll-behavior: smooth;
  overflow-y: auto !important;
  padding: 0 !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Optima", sans-serif;
  // scroll-behavior: smooth;
}

::selection {
  background-color: $primary;
  color: $offwhite;
}

::-webkit-selection {
  background-color: $primary;
  color: $offwhite;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Optima", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.2;
}

p {
  letter-spacing: 0.5px;
}

.swiper-slide {
  user-select: none;
  pointer-events: none;
}

.swiper-slide {
  img,
  h2,
  p {
    user-select: none;
    pointer-events: none;
  }
}
.swiper-pagination {
  --swiper-pagination-color: $white;
  --swiper-pagination-bottom: 20px;

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background-color: $white;
    transition: opacity 0.3s ease;

    &:hover {
      background-color: $white;
      opacity: 1;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $white;
  }
}

.main-content {
  padding-top: 6rem;
}

.swiper-pagination {
  z-index: 49 !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 10s linear infinite;
}

@media (max-width: 576px) {
  .main-content {
    padding-top: 3.5rem;
  }
}
